import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Container, Row, Col, Card } from "react-bootstrap";
import { navigate } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
  <Row className="px-4" mdxType="Row">
   <Col mdxType="Col">
          <h2 className="mt-4">Outreach</h2>
          <h3>Public</h3>
          <p>{`We will work with patient organisations and the broader public so that people are aware of the project and can provide feedback on it. In particular, we will be asking for feedback on the ethical, social and legal aspects of opening access to infectious disease data across Europe.`}</p>
          <p>{`We will also produce materials such as infographics and cartoons on what FAIR data is (i.e. data that is `}<strong parentName="p">{`F`}</strong>{`indable, `}<strong parentName="p">{`A`}</strong>{`ccessible, `}<strong parentName="p">{`I`}</strong>{`nteroperable and `}<strong parentName="p">{`R`}</strong>{`eusable). These materials will outline the work of the project, and show how making data FAIR will help us combat disease. The materials will also include a resource pack for high school teachers, to educate high school students about BY-COVID and FAIR data. We will publish a report on the effectiveness of these public engagement activities.`}</p>
          <h3 className="pt-4">Industry</h3>
          <p>{`We aim to map COVID-related activities in industry, and build a community of specialists to discuss visionary ideas, challenges and solutions in the area of COVID and infectious diseases.`}</p>
          <p>{`By doing this, we would like to demonstrate the importance of FAIR open data for the development of medicines, diagnostic tests and other products.
Our plan is to organise access days, aim days, Innovation and SME Forums, and publish use cases and reports describing the industry ecosystem in COVID and infectious diseases.`}</p>
          <p>{`If you want to be part of this community or to help us map the industry community and engage in our upcoming activities, please fill in our survey.`}</p>
          <button className="btn btn-primary" onClick={() => {
            navigate("https://www.surveymonkey.co.uk/r/ZXF6SCV");
          }}>Take the industry survey</button>
          <h3 className="pt-5">Funders</h3>
          <p>{`Showcasing the impact of the project to funding organisations is key to the continued investment in the infrastructure being developed by BY-COVID. We are planning two large stakeholder events to communicate the outputs of the project, and the value of open infectious disease data. These events will be aimed at funders and policy-makers, including the European Commission, national EOSC (European Open Science Cloud) representatives, and relevant EU agencies.`}</p>
          <p>{`We will further demonstrate the importance of FAIR COVID-19 and other infectious disease data and software to support innovation in academia and industry.`}</p>
          <p className="fw-bold mt-4">Updates on outreach work will be posted here.</p>
          <h2>{`Media`}</h2>
          <h3>Factsheets</h3>
          <p className="pb-2">
  Download the BY-COVID factsheet for an overview of the project. There are three different colour themes to choose from.
          </p>
          <Container className="full-width" mdxType="Container">
  <Row mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={6} lg={2} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="/pdf/BY-COVID_factsheet_dark.pdf">
          <Card.Img variant="top" src="/BY-COVID_factsheet_dark.jpg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="/pdf/BY-COVID_factsheet_dark.pdf" className="pb-3">Download</Card.Link>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={6} lg={2} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="/pdf/BY-COVID_factsheet.pdf">
          <Card.Img variant="top" src="/BY-COVID_factsheet_gradient.jpg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="/pdf/BY-COVID_factsheet.pdf" className="pb-3">Download</Card.Link>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={6} lg={2} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="/pdf/BY-COVID_factsheet_white.pdf">
          <Card.Img variant="top" src="/BY-COVID_factsheet_white.jpg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="/pdf/BY-COVID_factsheet_white.pdf" className="pb-3">Download</Card.Link>
      </Card>
    </Col>
  </Row>
          </Container>
          <h3 className="pt-3">Graphics</h3>
          <Container className="full-width" mdxType="Container">
  <Row mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4" mdxType="Card">
        <Card.Img variant="top" src="/by-covid-logo-light-bg.svg" className="border p-4" style={{
                    "maxHeight": "115px",
                    "background": "#ccc"
                  }} />
        <Card.Body className="position-relative">
          <Card.Title as="h3" className="mt-0 fw-bold fs-5 lh-sm">
            Logo (light background)
          </Card.Title>
          <Card.Text className="mt-3">
            <ul className="mb-0">
              <li>
                <a href="/by-covid-logo-light-bg.svg">SVG format</a>
              </li>
              <li>
                <a href="/by-covid-logo-light-bg.png">PNG format</a>
              </li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4" mdxType="Card">
        <Card.Img variant="top" src="/by-covid-logo-dark-bg.svg" className="p-4" style={{
                    "maxHeight": "115px",
                    "background": "#ccc"
                  }} />
        <Card.Body className="position-relative">
          <Card.Title as="h3" className="mt-0 fw-bold fs-5 lh-sm">
            Logo (dark background)
          </Card.Title>
          <Card.Text className="mt-3">
            <ul className="mb-0">
              <li>
                <a href="/by-covid-logo-dark-bg.svg">SVG format</a>
              </li>
              <li>
                <a href="/by-covid-logo-dark-bg.png">PNG format</a>
              </li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4" mdxType="Card">
        <Card.Img variant="top" src="/by-covid-icon.svg" className="p-4 border" style={{
                    "maxHeight": "100px",
                    "background": "#ccc"
                  }} />
        <Card.Body className="position-relative">
          <Card.Title as="h3" className="mt-0 fw-bold fs-5 lh-sm">
            Icon
          </Card.Title>
          <Card.Text className="mt-3">
            <ul className="mb-0">
              <li>
                <a href="/by-covid-icon.svg">SVG format</a>
              </li>
              <li>
                <a href="/by-covid-icon.png">PNG format</a>
              </li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  </Row>
          </Container>
          <p>
  {" "}
  You can find more graphics in the <a href="https://docs.google.com/presentation/d/1JSGeclDqn-OKHi0udYcNaIeSs_mMFi14/edit#slide=id.p1">
    <strong>BY-COVID brand guidelines</strong>
  </a>.
          </p>
          <span className="anchor" id="contact"></span>
          <h2>{`Contact us`}</h2>
          <p>{`For any enquiries please email `}<a parentName="p" {...{
              "href": "mailto:by-covid-admin@elixir-europe.org"
            }}>{`by-covid-admin@elixir-europe.org`}</a>{`.`}</p>
   </Col>
  </Row>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      